<template>
  <view-item title="库存统计报表">
    <template #operation>
      <en-button type="primary" @click="operation.total.click" v-if="tabs.active === 'outin'">查看总计</en-button>
      <en-button
        type="primary"
        @click="operation.export.click"
        v-if="
          (tabs.active === 'customer' && customer.form.data.mode === 'daily') ||
          (tabs.active === 'out' && out.form.data.mode === 'daily') ||
          tabs.active === 'outin' ||
          tabs.active === 'purchase'
        "
        >导出</en-button
      >
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="入库" name="customer" :style="{ height: `${height - 55}px`, overflow: 'auto' }">
              <form-query :method="customer.table[customer.mode]" :model="customer.form.data">
                <en-form-item label="统计类型">
                  <div class="flex flex-wrap">
                    <en-checkbox v-model="customer.mode" true-label="daily" false-label="daily" @change="customer.table[customer.mode]()">
                      按日统计
                    </en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="monthly" false-label="monthly" @change="customer.table[customer.mode]()">
                      按月统计
                    </en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="detail" false-label="detail" @change="customer.table[customer.mode]()">
                      按入库明细统计
                    </en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="type" false-label="type" @change="customer.table[customer.mode]()">
                      按入库分类统计
                    </en-checkbox>
                  </div>
                </en-form-item>

                <en-form-item label="入库日期">
                  <en-date-picker
                    v-if="customer.mode === 'monthly'"
                    v-model:start="customer.form.data.startMonth"
                    v-model:end="customer.form.data.endMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>

                  <en-date-picker
                    v-else
                    v-model:start="customer.form.data.startDate"
                    v-model:end="customer.form.data.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    v-if="customer.mode === 'daily'"
                    code="STISTAFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    :method="customer.table.daily"
                    pagination
                    show-summary
                    :summary="customer.table.summary.data"
                    :config="customer.table.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-else-if="customer.mode === 'monthly'"
                    code="STISTAFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    :method="customer.table.monthly"
                    pagination
                    show-summary
                    :summary="customer.table.summary.data"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-else-if="customer.mode === 'detail'"
                    code="SKINDTSTAFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    :method="customer.table.detail"
                    pagination
                    show-summary
                    :config="customer.table.config"
                    :summary="customer.table.summary.data"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-else-if="customer.mode === 'type'"
                    code="SKINTYPESTAFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    :method="customer.table.type"
                    pagination
                    show-summary
                    :config="customer.table.config"
                    :summary="customer.table.summary.data"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="出库" name="out" :style="{ height: `${height - 55}px`, overflow: 'auto' }">
              <form-query :method="out.table[out.mode]" :model="out.form.data">
                <en-form-item label="统计类型">
                  <div class="flex flex-wrap">
                    <en-checkbox v-model="out.mode" true-label="daily" false-label="daily" @change="out.table[out.mode]()"> 按日统计 </en-checkbox>
                    <en-checkbox v-model="out.mode" true-label="monthly" false-label="monthly" @change="out.table[out.mode]()">
                      按月统计
                    </en-checkbox>
                    <en-checkbox v-model="out.mode" true-label="detail" false-label="detail" @change="out.table[out.mode]()">
                      按入库明细统计
                    </en-checkbox>
                    <en-checkbox v-model="out.mode" true-label="type" false-label="type" @change="out.table[out.mode]()">
                      按入库分类统计
                    </en-checkbox>
                  </div>
                </en-form-item>

                <en-form-item label="出库日期">
                  <en-date-picker
                    v-if="out.mode === 'monthly'"
                    v-model:start="out.form.data.startMonth"
                    v-model:end="out.form.data.endMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>
                  <en-date-picker
                    v-else
                    v-model:start="out.form.data.startDate"
                    v-model:end="out.form.data.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    v-if="out.mode === 'daily' || out.mode === 'monthly'"
                    code="STOSTAFLD"
                    :height="height"
                    :data="out.table.data"
                    :loading="out.table.loading"
                    :paging="out.table.paging"
                    :method="out.table[out.mode]"
                    pagination
                    show-summary
                    :summary="out.table.summary.data"
                    :config="out.table.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-else-if="out.mode === 'detail'"
                    code="SKOTDTSTAFLD"
                    :height="height"
                    :data="out.table.data"
                    :loading="out.table.loading"
                    :paging="out.table.paging"
                    :summary="out.table.summary.data"
                    :method="out.table[out.mode]"
                    pagination
                    show-summary
                    :config="out.table.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-else-if="out.mode === 'type'"
                    code="SKOTTYPESTAFLD"
                    :height="height"
                    :data="out.table.data"
                    :loading="out.table.loading"
                    :paging="out.table.paging"
                    :summary="out.table.summary.data"
                    :method="out.table[out.mode]"
                    pagination
                    show-summary
                    :config="out.table.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                    <template #SUB_TYPE="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                      {{ `${row.subType ?? '--'}` }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="出入库" name="outin" :style="{ height: `${height - 55}px`, overflow: 'auto' }">
              <form-query :method="outin.table[outin.mode]" :model="outin.form.data">
                <en-form-item label="统计类型">
                  <div class="flex flex-wrap">
                    <en-checkbox v-model="outin.mode" true-label="daily" false-label="daily" @change="outin.table[outin.mode]()">
                      按日统计
                    </en-checkbox>
                    <en-checkbox v-model="outin.mode" true-label="monthly" false-label="monthly" @change="outin.table[outin.mode]()">
                      按月统计
                    </en-checkbox>
                    <en-checkbox v-model="outin.mode" true-label="goodsType" false-label="goodsType" @change="outin.table[outin.mode]()">
                      按配件类别汇总
                    </en-checkbox>
                    <en-checkbox v-model="outin.mode" true-label="warehouseType" false-label="warehouseType" @change="outin.table[outin.mode]()">
                      按仓库汇总
                    </en-checkbox>
                  </div>
                </en-form-item>
                <en-form-item label="数据展示">
                  <en-checkbox v-model="tax.taxInclusive" true-label="taxInclusive" disabled>含税</en-checkbox>
                  <en-checkbox v-model="tax.taxExclusive" true-label="taxExclusive">不含税</en-checkbox>
                </en-form-item>

                <en-form-item label="查询日期">
                  <en-date-picker
                    v-if="outin.mode === 'monthly'"
                    v-model:start="outin.form.data.startMonth"
                    v-model:end="outin.form.data.endMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>
                  <en-date-picker
                    v-else
                    v-model:start="outin.form.data.startDate"
                    v-model:end="outin.form.data.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <en-table :data="outin.table.data" :height="height" :loading="outin.table.loading" pagination :paging="outin.table.paging">
                    <en-table-column label="序号" type="index"></en-table-column>
                    <en-table-column label="日期" prop="date" v-if="outin.mode === 'daily'"></en-table-column>
                    <en-table-column label="日期" prop="date" v-if="outin.mode === 'monthly'"></en-table-column>
                    <en-table-column label="配件类别" prop="goodsCategoryName" v-if="outin.mode === 'goodsType'"> </en-table-column>
                    <en-table-column label="仓库" prop="warehouseName" v-if="outin.mode === 'warehouseType'"> </en-table-column>
                    <en-table-column label="入库数量" prop="in.count">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatNumber(row.in.count, 2) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="入库金额(含税)" prop="totalAmount">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatMoney(row.in.amount) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="入库金额(不含税)" prop="totalAmount" v-if="tax.taxExclusive">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatMoney(row.in.amountWithoutTaxRate) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="出库数量" prop="out.count">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatNumber(row.out.count, 2) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="出库金额(含税)" width="110" prop="totalAmount">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatMoney(row.out.amount) }}
                      </template>
                    </en-table-column>
                    <en-table-column label="出库金额(不含税)" prop="totalAmount" v-if="tax.taxExclusive">
                      <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                        {{ formatMoney(row.out.amountWithoutTaxRate) }}
                      </template>
                    </en-table-column>
                    <en-table-column header-align="center" label="库存初始化">
                      <en-table-column label="数量" prop="initial.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.initial.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.initial.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.initial.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="采购入库">
                      <en-table-column label="数量" prop="stockIn.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockInReturn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockIn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockIn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="采购退货">
                      <en-table-column label="数量" prop="stockReturn">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockInReturn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockInReturn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockInReturn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="其他入库">
                      <en-table-column label="数量" prop="stockInOther.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockInOther.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockInOther.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockInOther.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="盘点入库">
                      <en-table-column label="数量" prop="inventoryProfit.count ">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.inventoryProfit.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" prop="inventoryProfit.amount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.inventoryProfit.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.inventoryProfit.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="移库入库">
                      <en-table-column label="数量" prop="transferIn.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.transferIn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" prop="transferIn.amount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.transferIn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.transferIn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="调价入库">
                      <en-table-column label="数量" prop="costAdjustmentIn.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.costAdjustmentIn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" prop="costAdjustmentIn.amount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.costAdjustmentIn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.costAdjustmentIn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="生产入库">
                      <en-table-column label="数量" prop="manufacturingIn.count">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.manufacturingIn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" prop="manufacturingIn.amount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.manufacturingIn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.manufacturingIn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="销售出库">
                      <en-table-column label="数量" prop="stockOut">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockOut.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" width="110" prop="saleAmount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOut.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOut.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="销售退库">
                      <en-table-column label="数量" prop="stockOutReturn">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockOutReturn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" width="110" prop="saleReturnAmount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOutReturn.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOutReturn.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="其他出库">
                      <en-table-column label="数量" prop="otherCount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.stockOutOther.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)" width="110" prop="otherAmount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOutOther.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.stockOutOther.amountWithoutTaxRate) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="移库出库">
                      <en-table-column label="数量" prop="transferOutCount">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.transferOut.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatMoney(row.transferOut.amount) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.transferOut.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="领料出库">
                      <en-table-column label="数量">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisition.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisition.amount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisition.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="领料退库">
                      <en-table-column label="数量">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisitionReturn.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisitionReturn.amount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.materialRequisitionReturn.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="盘点出库">
                      <en-table-column label="数量">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.inventoryLoss.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.inventoryLoss.amount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.inventoryLoss.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="调价出库">
                      <en-table-column label="数量" prop="costAdjustmentOut">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.costAdjustmentOut.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.costAdjustmentOut.amount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.costAdjustmentOut.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                    <en-table-column header-align="center" label="生产领料">
                      <en-table-column label="数量" prop="manufacturingOut">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.manufacturingOut.count, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(含税)">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.manufacturingOut.amount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="金额(不含税)" v-if="tax.taxExclusive">
                        <template #default="{ row }: { row: EnocloudStatementDefinitions['StockReportDto'] }">
                          {{ formatNumber(row.manufacturingOut.amountWithoutTaxRate, 2) }}
                        </template>
                      </en-table-column>
                    </en-table-column>
                  </en-table>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="供应商汇总" name="purchase" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="purchase.table.daily" :model="purchase.form.data">
                <en-form-item label="日期">
                  <en-date-picker
                    v-model:start="purchase.form.data.startDate"
                    v-model:end="purchase.form.data.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="供应商" prop="supplierName">
                  <en-input v-model="purchase.form.data.supplierName"></en-input>
                </en-form-item>
                <en-form-item label="门店" prop="branchIds">
                  <select-maintain
                    v-model="purchase.form.data.branchIds"
                    :ajax="{
                      action: 'GET /enocloud/common/branch',
                      params: (params) => {
                        params.payload = { pagingEnabled: false }
                      }
                    }"
                    :props="{ label: 'shortName', value: 'id' }"
                    value-key="id"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="PUCRNSTATFLD"
                    :height="height"
                    :data="purchase.table.data"
                    :loading="purchase.table.loading"
                    :paging="purchase.table.paging"
                    :method="purchase.table.daily"
                    pagination
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="detail.visible" title="查看总计" size="20%">
    <div v-for="item in detail.table.data" class="flex flex-col gap-7">
      <div class="flex flex-col gap-4">
        <div>入库统计:</div>
        <div style="color: #4c58d9ff">入库数量:{{ item.in.count }}</div>
        <div style="color: #4c58d9ff">入库金额（含税）:￥{{ item.in.amount }}</div>
        <div style="color: #4c58d9ff">入库金额（不含税）:￥{{ item.in.amountWithoutTaxRate }}</div>
      </div>
      <div class="flex flex-col gap-4">
        <div>出库统计:</div>
        <div style="color: #4c58d9ff">出库数量:{{ item.out.count }}</div>
        <div style="color: #4c58d9ff">出库金额（含税）:￥{{ item.out.amount }}</div>
        <div style="color: #4c58d9ff">出库金额（不含税）:￥{{ item.out.amountWithoutTaxRate }}</div>
      </div>
    </div>

    <template #footer>
      <en-button type="primary" @click="detail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'
import { calculator } from '@enocloud/utils'
import { type TableColumnCtx } from '@enocloud/components'
import { useStore } from '@enocloud/utils'

export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              in: {
                action: 'GET /enocloud/statement/stock/in/:timeWindow/export',
                params(params) {
                  params.paths = ['daily']
                  params.payload = omit(this.customer.form.data, 'mode')
                }
              },
              out: {
                action: 'GET /enocloud/statement/stock/out/:timeWindow/export',
                params(params) {
                  params.paths = ['daily']
                  params.payload = omit(this.out.form.data, 'mode')
                }
              },
              outinDay: {
                action: 'GET /enocloud/statement/stock/daily/export',
                params(params) {
                  params.paths = ['daily']
                  params.payload = omit(this.outin.form.data, 'mode')
                }
              },
              outinMonth: {
                action: 'GET /enocloud/statement/stock/monthly/export',
                params(params) {
                  params.paths = ['monthly']
                  params.payload = omit(this.outin.form.data, 'mode')
                }
              },
              outinDetail: {
                action: 'GET /enocloud/statement/stock/category/export'
              },
              outinType: {
                action: 'GET /enocloud/statement/stock/warehouse/export'
              },
              purchase: {
                action: 'GET /enocloud/statement/purchase/return/export'
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'customer':
                  this.operation.export.in()
                  this.store.openDownload()
                  break
                case 'out':
                  this.operation.export.out()
                  this.store.openDownload()
                  break
                case 'outin':
                  if (this.outin.mode === 'daily') this.operation.export.outinDay()
                  if (this.outin.mode === 'monthly') this.operation.export.outinMonth()
                  if (this.outin.mode === 'goodsType') this.operation.export.outinDetail()
                  if (this.outin.mode === 'warehouseType') this.operation.export.outinType()
                  this.store.openDownload()
                  break
                case 'purchase':
                  this.operation.export.purchase()
                  this.store.openDownload()
                  break
              }
            }
          },
          total: {
            async click() {
              if (this.outin.mode === 'daily') {
                this.detail.form.data.mode = this.outin.mode
                this.detail.form.data.startDate = this.outin.form.data.startDate
                this.detail.form.data.endDate = this.outin.form.data.endDate
                await this.detail.table.daily()
              } else if (this.outin.mode === 'monthly') {
                this.detail.form.data.mode = this.outin.mode
                this.detail.form.data.startMonth = this.outin.form.data.startMonth
                this.detail.form.data.endMonth = this.outin.form.data.endMonth
                await this.detail.table.monthly()
              } else if (this.outin.mode === 'goodsType') {
                this.detail.form.data.mode = this.outin.mode
                this.detail.form.data.startDate = this.outin.form.data.startDate
                this.detail.form.data.endDate = this.outin.form.data.endDate
                await this.detail.table.goodsType()
              } else if (this.outin.mode === 'warehouseType') {
                this.detail.form.data.mode = this.outin.mode
                this.detail.form.data.startDate = this.outin.form.data.startDate
                this.detail.form.data.endDate = this.outin.form.data.endDate
                await this.detail.table.warehouseType()
              }
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              mode: 'daily',
              startDate: '',
              endDate: '',
              startMonth: dayjs().format('YYYY-MM'),
              endMonth: dayjs().format('YYYY-MM'),
              branchIds: []
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/stock/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.detail.form.data, 'branchIds', 'startDate', 'endDate', 'mode')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/stock/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.detail.form.data, 'branchIds', 'startMonth', 'endMonth', 'mode')
                }
              },
              goodsType: {
                action: 'GET /enocloud/statement/stock/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.detail.form.data, 'branchIds', 'startDate', 'endDate', 'mode')
                }
              },
              warehouseType: {
                action: 'GET /enocloud/statement/stock/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.detail.form.data, 'branchIds', 'startDate', 'endDate', 'mode')
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      },
      tabs: {
        active: 'customer'
      },
      tax: {
        taxInclusive: true,
        taxExclusive: false
      },
      customer: {
        mode: 'daily' as 'daily' | 'monthly' | 'detail' | 'type',
        children: {
          form: {
            data: {
              branchIds: [],
              startMonth: dayjs().format('YYYY-MM'),
              endMonth: dayjs().format('YYYY-MM'),
              startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD'),
              documentSerialNo: '',
              subType: '',
              goodsName: '',
              goodsOem: '',
              goodsSerialNo: '',
              goodsCategoryId: '',
              goodsVehicleSpec: '',
              warehouseId: '',
              warehouseShelf: ''
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/stock/in/:timeWindow',
                summary: 'GET /enocloud/statement/stock/in/:timeWindow/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.customer.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/stock/in/:timeWindow',
                summary: 'GET /enocloud/statement/stock/in/:timeWindow/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.customer.form.data, 'startMonth', 'endMonth')
                }
              },
              detail: {
                action: 'GET /enocloud/statement/stock/in/detail',
                summary: 'GET /enocloud/statement/stock/in/detail/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.customer.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              },
              type: {
                action: 'GET /enocloud/statement/stock/in/type',
                summary: 'GET /enocloud/statement/stock/in/type/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.customer.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              }
            },
            config: {
              DOCUMENT_SERIAL_NO: { header: { filter: { type: 'text', field: 'documentSerialNo' } } },
              GOODS_NAME: { header: { filter: { type: 'text', field: 'goodsName' } } },
              GOODS_OEM: { header: { filter: { type: 'text', field: 'goodsOem' } } },
              GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'goodsVehicleSpec' } } },
              WAREHOUSE_SHELF: { header: { filter: { type: 'text', field: 'warehouseShelf' } } },
              SUB_TYPE: { header: { filter: { type: 'text', field: 'subType' } } },
              WAREHOUSE_NAME: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'warehouseId',
                    props: {
                      ajax: { action: 'GET /enocloud/common/warehouse', params: (value) => ({ name: value }) },
                      props: { label: 'name', value: 'id' },
                      remote: true
                    }
                  }
                }
              },
              GOODS_CATEGORY_NAME: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'goodsCategoryId',
                    props: {
                      ajax: { action: 'GET /enocloud/common/goods/category', params: (value) => ({ name: value }) },
                      props: { label: 'name', value: 'id' },
                      remote: true
                    }
                  }
                }
              },
              TYPE: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'types',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/lookup/:lookupType',
                        params: (params) => (params.paths = ['INVHISCTYTYPE']),
                        convert(data) {
                          const res = data.find((item) => item.code === 'IN').children
                          return res
                        }
                      },
                      props: { label: 'message', value: 'code' }
                    }
                  }
                }
              }
            }
          }
        }
      },
      out: {
        mode: 'daily' as 'daily' | 'monthly' | 'detail' | 'type',
        children: {
          form: {
            data: {
              branchIds: [],
              startMonth: dayjs().format('YYYY-MM'),
              endMonth: dayjs().format('YYYY-MM'),
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD'),
              documentSerialNo: '',
              subType: '',
              goodsName: '',
              goodsOem: '',
              goodsSerialNo: '',
              goodsCategoryId: '',
              goodsVehicleSpec: '',
              warehouseId: '',
              warehouseShelf: ''
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/stock/out/:timeWindow',
                summary: 'GET /enocloud/statement/stock/out/:timeWindow/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.out.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/stock/out/:timeWindow',
                summary: 'GET /enocloud/statement/stock/out/:timeWindow/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.out.form.data, 'startMonth', 'endMonth', 'goodsCategoryId')
                }
              },
              detail: {
                action: 'GET /enocloud/statement/stock/out/detail',
                summary: 'GET /enocloud/statement/stock/out/detail/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.out.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              },
              type: {
                action: 'GET /enocloud/statement/stock/out/type',
                summary: 'GET /enocloud/statement/stock/out/type/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.out.form.data, 'startDate', 'endDate', 'goodsCategoryId')
                }
              }
            },
            config: {
              WAREHOUSE_SHELF: { header: { filter: { type: 'text', field: 'warehouseShelf' } } },
              DOCUMENT_SERIAL_NO: { header: { filter: { type: 'text', field: 'documentSerialNo' } } },
              TYPE: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'types',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/lookup/:lookupType',
                        params: (params) => (params.paths = ['INVHISCTYTYPE']),
                        convert(data) {
                          const res = data.find((item) => item.code === 'OUT').children
                          return res
                        }
                      },
                      props: { label: 'message', value: 'code' }
                    }
                  }
                }
              },
              GOODS_NAME: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'goodsName'
                  }
                }
              },
              GOODS_OEM: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'goodsOem'
                  }
                }
              },
              GOODS_SERIAL_NO: {
                header: {
                  filter: {
                    type: 'text',
                    field: 'goodsSerialNo'
                  }
                }
              },
              GOODS_CATEGORY_NAME: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'categoryId',
                    props: {
                      ajax: { action: 'GET /enocloud/common/goods/category', params: (value) => ({ name: value }) },
                      props: { label: 'name', value: 'id' },
                      remote: true
                    }
                  }
                }
              },
              GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'goodsVehicleSpec' } } },
              WAREHOUSE_NAME: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'warehouseId',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/warehouse',
                        params: (params, value) => (params.payload = { name: value })
                      },
                      remote: true,
                      props: { label: 'name', value: 'id' }
                    }
                  }
                }
              },
              SUB_TYPE: { header: { filter: { type: 'text', field: 'subType' } } }
            }
          }
        }
      },
      outin: {
        mode: 'daily' as 'daily' | 'monthly' | 'goodsType' | 'warehouseType',
        children: {
          form: {
            data: {
              branchIds: [],
              startMonth: dayjs().format('YYYY-MM'),
              endMonth: dayjs().format('YYYY-MM'),
              startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD')
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/stock/daily',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.outin.form.data, 'mode', 'branchIds', 'startDate', 'endDate')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/stock/monthly',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.outin.form.data, 'mode', 'branchIds', 'startMonth', 'endMonth')
                }
              },
              goodsType: {
                action: 'GET /enocloud/statement/stock/category',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.outin.form.data, 'mode', 'branchIds', 'startDate', 'endDate')
                }
              },
              warehouseType: {
                action: 'GET /enocloud/statement/stock/warehouse',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.outin.form.data, 'mode', 'branchIds', 'startDate', 'endDate')
                }
              }
            }
          }
        }
      },
      purchase: {
        mode: 'daily' as 'daily' | 'monthly' | 'detail' | 'type',
        children: {
          form: {
            data: {
              branchIds: [],
              supplierName: '',
              name: '',
              startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD')
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/purchase/return',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.purchase.form.data, 'mode', 'branchIds', 'startDate', 'endDate', 'supplierName', 'name')
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.customer.table.daily()
  }
})
</script>
